import React from "react";
import "../css/About.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="container-fluid about-section" id="about">
      <div className="row g-3">
        <div className="col-md-6">
          <div className="p-3 about-left-image"></div>
          <div className="p-3 about-body">
            <h2>
              Microbiologist by day,
              <br /> cookie momster by night
            </h2>
            <p>
              My name is Randi, I started making cookies as a hobby and quickly
              started filling orders for family and friends. Fast forward a
              couple of years, now I enjoy making cookies for anyone who gives
              me the opportunity!
            </p>{" "}
            <div className="button-container">
              <Link
                type="button"
                className="btn btn-dark order-btn"
                to="/orderform"
              >
                ORDER
              </Link>
            </div>
          </div>
        </div>{" "}
        <div className="col-md-6">
          <div className="p-3  about-right-image"> </div>
        </div>
      </div>
    </div>
  );
};

export default About;
