import React from "react";
import "../css/Testimonials.css";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

const Testimonials = () => {
  return (
    <div>
      <div className="container-fluid testimonial-section">
        <div className="row">
          <div className="col-md review">
            <p>
              <div className="col-md  quotation">
                <ImQuotesLeft />
              </div>{" "}
              I placed a last minute order and I was not disappointed! They were
              not only gorgeous, but also delicious. The cookies were a huge
              hit!{" "}
              <div className="col-md  quotation-2">
                <ImQuotesRight />
              </div>
            </p>
          </div>
          <div className="col-md review">
            <p>
              <div className="col-md  quotation">
                <ImQuotesLeft />
              </div>{" "}
              I ordered two dozen cookies for an event and the cookies were
              exceptional! Randi went above and beyond with her intricate
              designs and attention to detail.
              <div className="col-md  quotation-2">
                <ImQuotesRight />
              </div>
            </p>
          </div>
          <div className="col-md review">
            <p>
              <div className="col-md  quotation">
                <ImQuotesLeft />
              </div>{" "}
              The cookies were absolutely beautiful and delicious. Randi was
              really attentive and made sure they were exactly how I wanted
              them.
              <div className="col-md  quotation-2">
                <ImQuotesRight />
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
