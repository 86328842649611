import React from "react";
import "../css/Homepage.css";
import { Link } from "react-router-dom";

const Homepage = () => {
  return (
    <>
      <div className="container-fluid homepage">
        <div className="row">
          <div className="col-md-5 title">
            <h2>
              Seattle
              <br /> Cookie
              <br /> Momster
            </h2>
            <h3>custom cookies</h3>{" "}
          </div>
          <div className="col-md-7 image"></div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
