import React from "react";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid footer-section">
      {/* <div className="footer-text">
        <h1>questions?</h1>

         <div className="social-media">
          <p>@seattlecookiemomster</p>
          <p>seattlecookiemomster@gmail.com</p>
        </div>  
      </div> */}
      <div className="copyright">
        <small>
          © 2022 SeattleCookieMomster | Dev:{" "}
          <a href="mailto: selina.pn@outlook.com">selinapn</a>
        </small>
      </div>
    </div>
  );
};

export default Footer;
