import React from "react";
import Homepage from "./Homepage";
import About from "./About";
import InstagramFeed from "./InstagramFeed";
import Testimonials from "./Testimonials";
const Landing = () => {
  return (
    <div className="container-fluid">
      <Homepage />
      <About />
      <Testimonials />
      <InstagramFeed />
    </div>
  );
};

export default Landing;
