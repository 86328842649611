import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import OrderForm from "./components/OrderForm";
import Landing from "./components/Landing";
import Scrolltotop from "./components/ScrollToTop";
const App = () => {
  return (
    <Router>
      <Navbar />
      <Scrolltotop />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/orderform" component={OrderForm} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
