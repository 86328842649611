import React, { useState } from "react";
import "../css/OrderForm.css";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <div className="result-sent">
      <p>Order request sent! Please check your email.</p>
    </div>
  );
};

export default function OrderForm() {
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qa6lw5e",
        "template_se8evjw",
        e.target,
        "KqHNIbUSEMLCe6kIb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {}
      );
    e.target.reset();

    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 4000);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const form = e.currentTarget;
  //   if (form.checkValidity() === false) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }

  //   // setValidated(true);
  // };

  return (
    <body>
      <div>
        <div className="container-fluid contact-form-container  ">
          <div className="row contact-form">
            <div className="form-title">
              <h1> Order Request</h1>
            </div>
            <div className="col-md-6 form-body-text">
              <div className="important-details">
                <h4>Important Information:</h4>
                <ul>
                  <li>
                    <p>
                      <b>MINIMUM ORDER IS 1 DOZEN.</b> You can order any
                      quantity over a dozen (ie. 20, 22, 40, etc), and prices
                      will be adjusted.
                    </p>
                  </li>
                  <li>
                    <p>
                      Orders must be placed 2 weeks in advance. Small and simple
                      orders may be accommodated with one week advance notice.
                    </p>
                    <small></small>
                  </li>
                  <li>
                    <p>
                      Cookies contain almond extract, please specify if your
                      order needs to be nut free.{" "}
                    </p>
                  </li>
                  <li>
                    <p>Cookies are pick-up in Renton, WA. </p>
                    <small>
                      Delivery is subject to availability and additional charge.
                    </small>
                  </li>
                </ul>
              </div>
              <div className="row">
                {" "}
                <div className="col-lg-6 price-details">
                  <h4>Price Per Dozen:</h4>
                  <p>
                    $57+ <small>Mix of simple and detailed designs</small>
                    <p>
                      $65+ <small>Characters, logos, floral, etc</small>
                    </p>{" "}
                  </p>
                </div>
                <div className="col-lg-6 price-details">
                  <h4>Mini Cookies:</h4>
                  <p>
                    $45+ <small>Mix of simple and detailed designs</small>
                    <small>
                      <br />{" "}
                      <i>
                        Mini cookies can be added on to regular sets, and will
                        be priced accordingly. Please inquire for a quote.
                      </i>
                    </small>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 form-right">
              <form className="row g-3 request-form" onSubmit={sendEmail}>
                <div className="col-md-6 ">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="eventdate" className="form-label">
                    Event/Pick Up Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="eventdate"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="theme" className="form-label">
                    Theme
                  </label>
                  <input type="text" className="form-control" name="theme" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="colors" className="form-label">
                    Colors
                  </label>
                  <input type="text" className="form-control" name="colors" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="quantity" className="form-label">
                    Quantity
                  </label>
                  <input type="text" className="form-control" name="quantity" />
                </div>
                <div className="col-md-12">
                  <label htmlFor="details" className="form-label">
                    Provide any additional details/specifics that you would
                    like:
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="details"
                    rows={5}
                    placeholder="names, age, etc"
                  />
                </div>{" "}
                <div className="col-md-12 pt-2 d-grid">
                  <button type="submit" className="btn btn-dark submit-btn  ">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div>{result ? <Result /> : null}</div>
          </div>
        </div>
      </div>
    </body>
  );
}
