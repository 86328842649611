import React from "react";
import { InstagramGallery } from "instagram-gallery";
import "../css/InstagramFeed.css";
import image1 from "../img/image4.jpeg";
import image2 from "../img/image3.jpeg";
import image3 from "../img/image2.jpeg";
import image4 from "../img/image1.jpeg";
import image5 from "../img/image0.jpeg";
import image6 from "../img/image5.jpeg";
import { AiOutlineInstagram } from "react-icons/ai";

const InstagramFeed = () => {
  return (
    <body>
      {" "}
      <div className="container-fluid ig-container">
        <div className="ig-container-header">
          {" "}
          <AiOutlineInstagram />
          <small>
            {" "}
            <a
              href="https://www.instagram.com/seattlecookiemomster/"
              target="_blank"
              className="ig-link"
            >
              seattlecookiemomster
            </a>
          </small>
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-center img-container">
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid">
              {" "}
              <img src={image1} />
            </div>{" "}
          </a>
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid">
              {" "}
              <img src={image2} />
            </div>{" "}
          </a>
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid">
              {" "}
              <img src={image3} />
            </div>{" "}
          </a>
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid">
              {" "}
              <img src={image4} />
            </div>{" "}
          </a>
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid ig-hidden-1">
              {" "}
              <img src={image5} />
            </div>{" "}
          </a>
          <a
            href="https://www.instagram.com/seattlecookiemomster/"
            target="_blank"
          >
            {" "}
            <div className="d-flex flex-column img-grid ig-hidden-2">
              {" "}
              <img src={image6} />
            </div>{" "}
          </a>
        </div>

        {/* <InstagramGallery
          accessToken="IGQVJVbS14ZAVpsV05GMll1N0pIcnV2ZA2FhWUk2MFRNSVQ0QzZANaVNSdzctY20zWUNqRGpscE5nb3BQSWxRZAFhWcTM1azhVMGhUNG1kYVhxZAFdvU082dVpESThSdEt5WlkydWxKLUQtenQ3ZAXJmMTZA5aAZDZD"
          count={6}
          pagination={false}
        /> */}

        {/* <div class="elfsight-app-ce7b1c3c-2b35-4088-a8fa-d3cc546a668f"></div> */}

        {/* <div className="img-container-grid">
          <div className="smallsquare">
            <a href="https://www.instagram.com/seattlecookiemomster/">
              <img src={image1} className="img-grid-c" />
            </a>
          </div>{" "}
          <div className="smallsquare">
            <a href="https://www.instagram.com/seattlecookiemomster/">
              <img src={image1} className="img-grid-c" />
            </a>
          </div>{" "}
          <div className="smallsquare">
            <a href="https://www.instagram.com/seattlecookiemomster/">
              <img src={image1} className="img-grid-c" />
            </a>
          </div>{" "}
          <div className="smallsquare">
            <a href="https://www.instagram.com/seattlecookiemomster/">
              <img src={image1} className="img-grid-c" />
            </a>
          </div>{" "}
          <div className="smallsquare">
            <a href="https://www.instagram.com/seattlecookiemomster/">
              <img src={image1} className="img-grid-c" />
            </a>
          </div>{" "}
        </div> */}
      </div>
    </body>
  );
};

export default InstagramFeed;
